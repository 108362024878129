import logo from './logo.svg';
import './App.css';
import amplitude from 'amplitude-js';
import React, { useEffect, useState } from 'react'
import { GlobalProvider } from './contexts/GlobalProvider'
import AlternativeDiscovery from './pages/Main'
import { logEvent } from './utils/logEvent'
import { getData } from './hooks/useApi';
import { v4 as uuidv4 } from 'uuid';

function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  console.log(params.userEmail)
  const  userLocalId = params.userEmail || "guest@splashup.co"
  let userEmail =  params.userEmail || "guest@splashup.co"
  let splashupUserId: string
  let localStorageId = uuidv4();
  try {
    if (!localStorage.getItem('splashup-user-ID')) localStorage.setItem('splashup-user-ID',localStorageId) 
    splashupUserId = localStorage.getItem('splashup-user-ID') || localStorageId
  } catch{
    splashupUserId = localStorageId
  }
  
  console.log('splashupUserId = ',splashupUserId)
  const  shopId = params.shopid || 'localhost:8080'
  const orderValue = params.orderValue
  const orderId = params.orderId
  const screenSize = params.screenSize
  const coupon = params.coupon
  let orderedProducts = []
  try {
    orderedProducts = JSON.parse(decodeURIComponent(params.products))
   } catch {
    console.log('error with prouct fetching', params.products)
   } 

// var instance1 = amplitude.getInstance().init("fae914421915e696fcea64bac652432c");

// const logEvent = async (event: string, payload: any) => {
//   try {
//     amplitude.getInstance().logEvent(event, payload);
//   } catch (err) {
//     const errorMessage = err
//     console.error('Could not log the event', errorMessage)
//   }

//   return
// }

  return (
    <GlobalProvider>
      <AlternativeDiscovery 
        hostId={shopId} 
        userGlobalId={splashupUserId} 
        userLocalId={userLocalId} 
        orderValue={orderValue}
        orderId={orderId}
        userName = {params.userName}
        location = {params.userLocation}
        screenSize={screenSize}
        coupon = {coupon}
        orderedProducts = {orderedProducts}
      ></AlternativeDiscovery>
    </GlobalProvider>
  );
}

export default App; 
