import React, { FC, HTMLAttributes, useEffect, useState } from 'react'
import Product from './Product'
import USPIcon from '../uspIcons'
// import OfferImage from '../../assets/icons8-hot-price (1).gif'
import OfferLayout from './OfferLayout'
//import OfferImage from '../../assets/AcademicGlassHyena-max-1mb.gif'
// import OfferImage from '../../assets/icons8-coupon.gif'

const StoreBlock: FC<IProps> = ({ adInfo, handleButtonClick }): JSX.Element => {
  
  const [showOffer, setShowOffer] = useState(false) 
  const [productTitleHeight, setproductTitleHeight] = useState('28px') 
  let blockHeight: number;
  useEffect(() => {
    blockHeight = document.getElementById(adInfo.storeData.storeId)!.offsetHeight;
    // console.log('blockHeight', blockHeight)
    calculateHeight()
  },[])
  const getLineCount = (text) => {
    let lines = 1;
    let cursor = 0;
    let charLineLimit = 25;
    let words = text.split(' ');
    for (let word of words) {
        
        if (cursor + word.length <= charLineLimit) cursor += word.length + 1;
        if (cursor + word.length > charLineLimit) {
            lines++;
            cursor = word.length + 1;
        }
        
    }
    
    
    return lines
  }
  
  function calculateHeight() {
    let flag = 0
    for (let product of adInfo.productsData) {
        console.log('title: ' + product.title)
        console.log('lines: ' + getLineCount(product.title))
        if (getLineCount(product.title) > 1) { flag = 1; break}        
    }
    (flag === 0) ?  setproductTitleHeight('16px') : setproductTitleHeight('28px') 
  }

  
  let splashupBlockItemList = {
    // display: 'flex',
    // flexDirection: 'row' as 'row',
    // justifyContent: 'flex-start',
    // marginTop: '2px',
    // overflowX: 'hidden' as 'hidden'
  }
  const splashupStoreBlock = {
    marginBottom: '25px',
    position: 'relative' as 'relative',
    width: '100%'
    }
    const splashupBlockHeader = {
        marginTop: '10px',
        paddingLeft: '0px',
        paddingRight: '0px',
        display: 'flex',
        justifyContent: 'space-between',
    }
    const splashupBlockHeaderLeft = {
        display: 'flex',
                
    }
    const splashupBlockHeaderStoreLogo = {
        width: '40px',
        height: '40px'
    }
    const splashupBlockShopInfo = {
        marginLeft: '8px',
        marginRight: '8px',
    }
    const splashupBlockShopName = {
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'black',
        paddingBottom: '4px'
    }
    const splashupBlockShopText = {
        // color: '#606060',
        // fontSize: '14px',
    }
    const splashupBlockHeaderRightLink = {
        color: '#606060',
        textDecoration: 'none',
        fontSize: '14px',
        backgroundColor: '#F4F4F4',
        border:'none',
        width: '96px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '10px',
        height: '25px',
        justifyContent: 'center'
    }

    let blockListClassName = ''
    if (adInfo.productsData.length > 3) {
        blockListClassName='splashup-block-item-list-scrollable-desktop' 
    } else blockListClassName='splashup-block-item-list-nonscrollable'

    const offerData = {
        storeId: adInfo.storeData.storeId,
        store: adInfo.storeData.name,
        storeLogo: adInfo.storeData.storeLogo,
        storeURL: adInfo.storeData.storeURL,
        text: adInfo.storeData.offerData.text,
        couponCode: adInfo.storeData.offerData.couponCode
    }
    return (
        <div id={adInfo.storeData.storeId} style={splashupStoreBlock}>
            {
                showOffer &&
                <OfferLayout offerData={offerData} setShowOffer={setShowOffer} handleButtonClick={handleButtonClick}/>
            }
                    <div style={splashupBlockHeader} className="splashup-block-header">
                        <div style={splashupBlockHeaderLeft} className="splashup-block-header-left">
                            <img style={splashupBlockHeaderStoreLogo} src = {adInfo.storeData.storeLogo} />
                            <div style={splashupBlockShopInfo} className="splashup-block-shop-info">
                                <div style={splashupBlockShopName} className="splashup-block-shop-name">{adInfo.storeData.name}</div>
                                <div style={splashupBlockShopText} className="splashup-block-shop-text">{adInfo.storeData.actionPhrase}</div>
                            </div>    
                        </div>
                        <div className="splashup-block-header-right">
                            {
                                adInfo.storeData.offerData.text &&
                                <button className="splashup-block-offer-button" onClick={()=>{setShowOffer(true)}}>
                                    Offer 
                                    <svg className="splashup-offer-icon" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" >
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M17 14h-4.341a6 6 0 1 1 0-4H23v4h-2v4h-4v-4zM7 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
                                        </g>
                                    </svg>
                                    
                                </button>
                            }
                            <button className="splashup-block-header-right-link" onClick={()=>{handleButtonClick('shop-click', '', adInfo.storeData.storeId, "https://" + adInfo.storeData.storeURL, '')}}>
                                Visit shop 
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.79289 6.5L4.14645 2.85355C3.95118 2.65829 3.95118 2.34171 4.14645 2.14645C4.34171 1.95118 4.65829 1.95118 4.85355 2.14645L8.85355 6.14645C9.04882 6.34171 9.04882 6.65829 8.85355 6.85355L4.85355 10.8536C4.65829 11.0488 4.34171 11.0488 4.14645 10.8536C3.95118 10.6583 3.95118 10.3417 4.14645 10.1464L7.79289 6.5Z" fill="#545454"/>
                                </svg>

                            </button>    
                        </div>
                    </div>
                    {
                        adInfo.storeData.USPData[0] &&
                        <div className="splashup-block-USP-line">
                        {
                            adInfo.storeData.USPData.map((USP) => (
                                <div className='USP-element'>
                                    <USPIcon type={USP.icon} size="15" color="#8A8C94"/>
                                    <div className='USP-item-title'> {USP.text}</div>
                                </div>
                            ))
                        }
                        </div>
                    }
                    
                    <div>
                        <div style={splashupBlockItemList} className={blockListClassName}> 
                        {
                            (adInfo.productsData.map(((productData: { title: string; image: string; price: number; reviewsNumber: number; rating: number; promoPrice: number; id: string; storeId: string, URL: string }) => (
                                <Product productData = {productData} settings = {adInfo.storeData.settings} handleButtonClick={handleButtonClick} titleHeight={productTitleHeight}/>
                            ))))
                        } 
                        </div>
                    </div>
                </div>
            
        
  )
}


interface IProps {
    adInfo: {
        storeData: {
            name: string,
            actionPhrase: string,
            storeLogo: string,
            storeId: string,
            storeURL: string,
            offerData: {
                text: string,
                couponCode: string,
            },
            USPData: {
                icon: string,
                text: string
            }[],
            settings: any
        },
        productsData: {
            title: string,
            image: string,
            price: number,
            promoPrice: number,
            id: string,
            storeId: string,
            URL: string,
        }[]
   
  },
  handleButtonClick: (actionType: string, productId: string, storeId: string, productURL: string, productTitle: string) => void
}

export default StoreBlock