import React, {
  FC,
  HTMLAttributes,
  useContext,
  useEffect,
  useState,
} from "react";
import { GlobalProviderState } from "../../contexts/GlobalProvider";

const StoreOfferMobileEmbedded: FC<IProps> = ({
  offerData,
  modalStatus,
  storeName,
  storeId,
  storeURL,
  setShowOffer,
  handleEmailSubmit,
}): JSX.Element => {
  //using context
  const { hostName } = useContext(GlobalProviderState);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [userEmail, setUserEmail] = useState(params.userEmail);
  const [modalScreen, setModalScreen] = useState("initial");
  const [modalId, setModalId] = useState("");
  const copyCoupon = () => {
    navigator.clipboard.writeText(offerData.couponCode);
  };
  useEffect(() => {
    modalStatus
      ? setTimeout(() => {
          setModalId("splashup-store-mobile-offer-modal-subcontainer");
        }, 10)
      : setModalId("splashup-store-mobile-offer-modal-subcontainer1");
  }, [modalStatus]);

  const handleOfferModalButton = (storeId: string, userEmail: string) => {
    setModalScreen("success");
    handleEmailSubmit(storeId, userEmail);
  };

  const splashupBlockHeaderRightLink = {
    width: "190px",
    marginTop: "4px",
    marginBottom: "12px",
  };
  const couponContainerStyle = {
    display: "flex",
    width: "200px",
    alignItems: "center",
    justifyContent: "center",
    border: "black dotted",
    borderRadius: "5px",
    padding: "5px",
    fontWeight: "bold",
    marginBottom: "8px",
  };
  return (
    <div className="store-mobile-embedded-offer-container">
      <div
        id={modalId}
        className="store-mobile-embedded--offer-modal-subcontainer"
      >
        {modalScreen == "initial" && (
          <div className="store-mobile-offer-content">
            {/* <div className="splashup-mobile-embedded-offer-CTA-text">
        ✨Summer Giveaway✨
        </div> */}

            <input
              type="text"
              className="splashup-mobile-offer-email-input"
              value={userEmail}
              onChange={(event) => setUserEmail(event.target.value)}
            />

            <button
              style={splashupBlockHeaderRightLink}
              className="splashup-offer-shop-button"
              onClick={() => {
                handleOfferModalButton(storeId, userEmail);
              }}
            >
              Get your unique code
            </button>
          </div>
        )}
        {modalScreen === "success" && (
          <div className="store-mobile-offer-content">
            <div
              style={{
                marginTop: "29px",
                marginBottom: "29px",
                textAlign: "center",
              }}
              className="splashup-mobile-embedded-offer-CTA-text"
            >
              Your code has been sent
            </div>

            <button
              style={splashupBlockHeaderRightLink}
              className="splashup-offer-shop-button"
              onClick={() => {
                window.open("https://" + storeURL, "_blank")!.focus();
              }}
            >
              Shop Now
            </button>
          </div>
        )}
        {modalScreen === "initial" && (
          <a
            onClick={() => {
              window
                .open(
                  "https://www.splashup.co/consumer-privacy-policy",
                  "_blank"
                )!
                .focus();
            }}
            className="splashup-link-small-light-grey"
            style={{ marginBottom: "8px" }}
          >
            Privacy | Terms and Conditions
          </a>
        )}
      </div>
    </div>
  );
};

interface IProps {
  offerData: {
    text: string;
    couponCode: string;
  };
  modalStatus: boolean;
  storeName: string;
  storeId: string;
  storeURL: string;
  setShowOffer: (state: boolean) => void;
  handleEmailSubmit: (storeId: string, userEmail: string) => void;
}

export default StoreOfferMobileEmbedded;
