import React, { FC, HTMLAttributes, useContext, useEffect, useState } from 'react'
import Product from './Product'
import StoreBlock from './StoreBlock'
import StoreBlockMobile from './StoreBlockMobile'
import { GlobalProviderState } from '../../contexts/GlobalProvider'

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { Pagination, EffectCoverflow } from 'swiper/modules';
import './style.css';

import USPIcon from '../uspIcons'

const LayoutOptionTwo: FC<IProps> = ({ adsList, handleButtonClick, handleShowMoreClick, showMoreFlag, screenSize }): JSX.Element => {
    const {widgetSettings, hostName} = useContext(GlobalProviderState)
    // const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 450px)").matches)
    // useEffect(() => { 
    //   const handler = (e: { matches: React.SetStateAction<boolean> }) => setIsMobile(e.matches)
    //   window.matchMedia("(max-width: 450px)").addEventListener('change', handler)}, [])
    const [swiped, setSwiped] = useState(0);
    const splashupBlockContainer = {
        fontFamily: "'Inter', sans-serif",
        borderRadius: '10px',
        border: '1px solid #D9D9D9',
        padding: '14px',
        maxWidth: '544px',
        backgroundColor: 'white'
    }
    
    const splashupBlockTextBig = {
        fontSize: '20px',
        color: '#333333'
    }
    const splashupBlockTextBigMobile = {
        fontSize: '24px',
        color: '#333333'
    }
    const splashupBlockTopLine = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
    }
    const splashupBlockSplashupLogoLink = {
        textDecoration: 'none', 
        display: 'flex'
    }
    const splashupBlockSplashupLogo = {
        color: '#414141',
        fontSize: '14px',
        //fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        width: '145px',
        alignItems: 'center'        
    }
    const splashupBlockSplashupLogoMobile = {
        color: '#414141',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }
    
    const trackingFrame = {
                width: '1px',
                height: '1px',
                border: 'none',
    }
    const pagination = {
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 3,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
      };

    const slideChanged = () => {
        console.log('slide change');
         setSwiped(1);
    }

    return (
    
    <section id="splashup-discovery-module">
    
      { (adsList.length>0) && screenSize=="desktop" &&
        <div style={splashupBlockContainer} id="splashup-container">
                <div style={splashupBlockTopLine}>
                    <div style={splashupBlockTextBig}>
                        {widgetSettings.topLine}
                    </div>
                </div>

                {/* <Swiper
                    // install Swiper modules
                    pagination={pagination}
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    modules={[Pagination, EffectCoverflow]}
                    spaceBetween={50}
                    slidesPerView={1}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={slideChanged}
                > */}
                { 
                    (adsList.length > 0) &&
                        (adsList.map( (adInfo, index) => (
                            // <SwiperSlide>
                            <StoreBlock adInfo={adInfo}  handleButtonClick={handleButtonClick} />
                            // </SwiperSlide>
                    )))
                }  
                {/* <div className={`${swiped == 0 ? 'alert-shown' : 'alert-hidden'}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '10px', color: '#333333', marginRight: '5px', height: '35px'}}>
                        <USPIcon type={"swipe"} size="
                        35" color="#8A8C94"/>&nbsp; Swipe!
                    </div>
                </Swiper> */}
                <div>
                {   
                    !showMoreFlag &&
                    <div className='splashup-centered-block'>
                        <button onClick={handleShowMoreClick} className="splashup-light-grey-button">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 7.29291C16.0976 7.68343 16.0976 8.31659 15.7071 8.70712L10.7071 13.7071C10.5196 13.8947 10.2652 14 10 14C9.73478 14 9.48043 13.8947 9.29289 13.7071L4.29289 8.70711C3.90237 8.31658 3.90237 7.68342 4.29289 7.29289C4.68342 6.90237 5.31658 6.90237 5.70711 7.29289L10 11.5858L14.2929 7.29291C14.6834 6.90238 15.3166 6.90238 15.7071 7.29291Z" fill="gray"/>
                            </svg>
                            Show more
                        </button>
                    </div>
                } 
                <div style={{display: 'flex', justifyContent:'center', marginTop: '5px'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div className='splashup-mobile-footer-host-recommendation'>
                                Recommended by {hostName}
                            </div>
                            <div style={splashupBlockSplashupLogoLink} className="splashup-block-splashuplogo-link" onClick={ () => {window.open('https://www.splashup.co/', '_blank')!.focus()}}>
                                <div style={splashupBlockSplashupLogoMobile} className="splashup-block-splashup-logo">
                                    Powered by 
                                    <div style={{display: 'flex', alignItems: 'flex-start', marginLeft: '4px'}}>
                                        Splashup
                                        <svg style={{marginLeft: '1px', paddingBottom: '7px'}} width="7" height="7" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="30.5" cy="30.5" r="29.5" stroke="#414141" strokeWidth="2"/>
                                            <path d="M21.2756 43V16.8182H31.6051C33.5824 16.8182 35.2699 17.1719 36.6676 17.8793C38.0739 18.5781 39.1435 19.571 39.8764 20.858C40.6179 22.1364 40.9886 23.6406 40.9886 25.3707C40.9886 27.1094 40.6136 28.6051 39.8636 29.858C39.1136 31.1023 38.027 32.0568 36.6037 32.7216C35.1889 33.3864 33.4759 33.7188 31.4645 33.7188H24.5483V29.2699H30.5696C31.6264 29.2699 32.5043 29.125 33.2031 28.8352C33.902 28.5455 34.4219 28.1108 34.7628 27.5312C35.1122 26.9517 35.2869 26.2315 35.2869 25.3707C35.2869 24.5014 35.1122 23.7685 34.7628 23.1719C34.4219 22.5753 33.8977 22.1236 33.1903 21.8168C32.4915 21.5014 31.6094 21.3438 30.544 21.3438H26.8111V43H21.2756ZM35.4148 31.0852L41.9219 43H35.8111L29.4446 31.0852H35.4148Z" fill="#414141"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                    </div>

                </div>
                
                </div>

                
            </div>
        }
        {
            (adsList.length>0) &&  screenSize=="mobile" &&
            <div style={splashupBlockContainer} id="splashup-container">
                <div style={splashupBlockTopLine}>
                    <div style={splashupBlockTextBigMobile}>{widgetSettings.topLine}</div>
                    
                </div>
                
                {/* <Swiper
                    // install Swiper modules
                    pagination={pagination}
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    modules={[Pagination, EffectCoverflow]}
                    spaceBetween={50}
                    slidesPerView={1}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={slideChanged}
                > */}
                    { 
                        (adsList.length > 0) &&
                            (adsList.map( (adInfo) => (     
                            // <SwiperSlide>
                             <StoreBlockMobile adInfo={adInfo} handleButtonClick={handleButtonClick}/>
                            // </SwiperSlide>
                        )))
                }
                {/* { 
                    <div className={`${swiped == 0 ? 'alert-shown' : 'alert-hidden'}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '10px', color: '#333333', marginRight: '5px', height: '35px'}}>
                        <USPIcon type={"swipe"} size="
                        35" color="#8A8C94"/>&nbsp; Swipe!
                    </div>
                }   
                </Swiper> */}
                {   
                    !showMoreFlag &&
                    <div className='splashup-centered-block'>
                        <button onClick={handleShowMoreClick} className="splashup-light-grey-button">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 7.29291C16.0976 7.68343 16.0976 8.31659 15.7071 8.70712L10.7071 13.7071C10.5196 13.8947 10.2652 14 10 14C9.73478 14 9.48043 13.8947 9.29289 13.7071L4.29289 8.70711C3.90237 8.31658 3.90237 7.68342 4.29289 7.29289C4.68342 6.90237 5.31658 6.90237 5.70711 7.29289L10 11.5858L14.2929 7.29291C14.6834 6.90238 15.3166 6.90238 15.7071 7.29291Z" fill="gray"/>
                            </svg>
                            Show more
                        </button>
                    </div>
                } 
                <div className='splashup-mobile-footer-host-recommendation'>
                    Recommended by {hostName}
                </div>
                <div style={splashupBlockSplashupLogoLink} className="splashup-block-splashuplogo-link" onClick={ () => {window.open('https://www.splashup.co/', '_blank')!.focus()}}>
                    <div style={splashupBlockSplashupLogoMobile} className="splashup-block-splashup-logo">
                        Powered by 
                        <div style={{display: 'flex', alignItems: 'flex-start', marginLeft: '4px'}}>
                            Splashup
                            <svg style={{marginLeft: '1px', paddingBottom: '7px'}} width="7" height="7" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30.5" cy="30.5" r="29.5" stroke="#414141" strokeWidth="2"/>
                                <path d="M21.2756 43V16.8182H31.6051C33.5824 16.8182 35.2699 17.1719 36.6676 17.8793C38.0739 18.5781 39.1435 19.571 39.8764 20.858C40.6179 22.1364 40.9886 23.6406 40.9886 25.3707C40.9886 27.1094 40.6136 28.6051 39.8636 29.858C39.1136 31.1023 38.027 32.0568 36.6037 32.7216C35.1889 33.3864 33.4759 33.7188 31.4645 33.7188H24.5483V29.2699H30.5696C31.6264 29.2699 32.5043 29.125 33.2031 28.8352C33.902 28.5455 34.4219 28.1108 34.7628 27.5312C35.1122 26.9517 35.2869 26.2315 35.2869 25.3707C35.2869 24.5014 35.1122 23.7685 34.7628 23.1719C34.4219 22.5753 33.8977 22.1236 33.1903 21.8168C32.4915 21.5014 31.6094 21.3438 30.544 21.3438H26.8111V43H21.2756ZM35.4148 31.0852L41.9219 43H35.8111L29.4446 31.0852H35.4148Z" fill="#414141"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        }
    </section>
  )
}


interface IProps {
    adsList: any[],
    handleButtonClick: (actionType: string, productId: string, storeId: string, productURL: string, productTitle: string) => void 
    handleShowMoreClick: () => void,
    showMoreFlag: boolean
    screenSize: string,
}

export default LayoutOptionTwo