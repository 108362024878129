import { FC, useEffect, useState } from "react";
import { Column, Row } from "../../design/components/grid";

const Product: FC<IProps> = ({
  productData,
  settings,
  handleButtonClick,
  titleHeight,
}): JSX.Element => {
  const [hoverState, setState] = useState({});
  const [updatedSettings, setUpdatedSettings] = useState({
    showPrices: true,
    showReviews: false,
  });
  useEffect(() => {
    if (settings != null) {
      setUpdatedSettings(settings);
    }
  }, []);
  function getInitialState() {
    return { hover: false };
  }
  const cropTitle = (text) => {
    let lines = 1;
    let cursor = 0;
    let charLineLimit = 25;
    let words = text.split(" ");
    let result = "";
    for (let word of words) {
      if (cursor + word.length <= charLineLimit) {
        result += word + " ";
        cursor += word.length + 1;
      } else {
        lines += 1;
        if (lines > 2) return result;
        result += word + " ";
        cursor = word.length + 1;
      }
    }
    return result;
  };
  function toggleHover() {
    setState(!hoverState);
  }
  function render() {
    if (hoverState) {
      return {
        marginBottom: "6px",
        marginRight: "2px",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "flex-start",
        cursor: "pointer",
        padding: "4px",
        minWidth: "156px",
        maxWidth: "156px",
      };
    } else {
      return {
        marginBottom: "6px",
        marginRight: "2px",
        backgroundColor: "#F9F9F9",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "flex-start",
        cursor: "pointer",
        padding: "4px",
        minWidth: "156px",
        maxWidth: "156px",
      };
    }
  }
  let splashupBlockItem = render();
  const splashupBlockItemImage = {
    width: "147px",
    height: "147px",
    borderRadius: "5px 5px 0 0",
    border: "1px solid #D9D9D9",
    objectFit: "contain" as "contain",
  };
  const splashupBlockItemTitle = {
    fontSize: "13px",
    fontWeight: "500",
    marginTop: "6px",
    color: "black",
    paddingLeft: "4px",
    textAlign: "left" as "left",
    width: "100%",
    height: titleHeight,
    lineHeight: "18px",
  };
  const splashupBlockItemPrice = {
    fontSize: "13px",
    fontWeight: "600",
    marginTop: "20px",
    paddingLeft: "4px",
    textAlign: "left" as "left",
    minHeight: "18px",
    maxHeight: "18px",
  };
  return (
    <Column
      style={splashupBlockItem}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onTouchStart={toggleHover}
      onTouchEnd={toggleHover}
      className="splashup-block-item"
      onClick={() =>
        handleButtonClick(
          "product-click",
          productData.id,
          productData.storeId,
          productData.URL,
          productData.title
        )
      }
       minHeight="235px"
    >
      <img
        style={splashupBlockItemImage}
        className="splashup-block-item-image"
        src={productData.image}
        alt=""
      />

      <Row style={splashupBlockItemTitle} className="splashup-block-item-text" minHeight="28px">
        {cropTitle(productData.title)}
      </Row>
      {/* This block displays the reviews, turned off for now         */}
      {/* {
          updatedSettings.showReviews &&
          productData.reviewsNumber && 
          <div className='splashup-block-item-rating'>
            <ReactStars
              size = {12}
              value = {productData.rating}
              edit = {false}
              color1 = {"#E3CDC9"}
              color2 = {"gray"}
            />
          <span style={{fontSize: '11px', marginLeft: '4px', paddingTop: '0px'}}>{productData.reviewsNumber} Reviews</span>  
          </div>
        } */}
      <Row minHeight="21px">
        {updatedSettings.showPrices && productData.promoPrice > 0 && (
          <div className="splashup-block-item-price-row">
            <div
              style={splashupBlockItemPrice}
              className="splashup-block-item-price-crossed"
            >
              ${productData.price.toFixed(2)}
            </div>
            <div
              style={splashupBlockItemPrice}
              className="splashup-block-item-price-promo"
            >
              ${productData.promoPrice.toFixed(2)}
            </div>
          </div>
        )}
        {updatedSettings.showPrices &&
          (!productData.promoPrice || productData.promoPrice == 0) && (
            <div
              style={splashupBlockItemPrice}
              className="splashup-block-item-text"
            >
              ${productData.price.toFixed(2)}
            </div>
          )}
      </Row>
    </Column>
  );
};

interface IProps {
  productData: {
    title: string;
    image: string;
    price: number;
    promoPrice: number;
    id: string;
    storeId: string;
    URL: string;
    reviewsNumber: number;
    rating: number;
  };
  settings: any;
  titleHeight: string;
  handleButtonClick: (
    actionType: string,
    productId: string,
    storeId: string,
    productURL: string,
    productTitle: string
  ) => void;
}

export default Product;
