import { Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import ReactStars from "react-stars";
import { Column, Row } from "../../design/components/grid";

const ProductImage = styled("img")({
  width: "120px",
  height: "120px",
  borderRadius: "8px 8px 0 0 ",
  border: "1px solid #D9D9D9",
  objectFit: "contain",
});

const ProductTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  marginTop: "12px",
  marginBottom: "6px",
  color: theme.palette.text.primary,
  textAlign: "left",
  width: "99%",
  maxHeight: "37px",
  minHeight: "37px",
  overflow: "hidden",
}));

const ProductPrice = styled(Typography)<{ crossed?: boolean }>(
  ({ theme, crossed }) => ({
    fontSize: "14px",
    fontWeight: "600",
    textAlign: "left",
    marginTop: "6px",
    color: crossed ? "#bcbcbc" : "#c1221e",
    textDecoration: crossed ? "line-through" : "none",
  })
);

const ProductPriceNormal = styled(Typography)({
  fontSize: "14px",
  fontWeight: "600",
  textAlign: "left" as "left",
  marginTop: "6px",
});

const ProductMobile = ({
  productData,
  settings,
  handleButtonClick,
  titleHeight,
}: Props) => {
  const [hoverState, setState] = useState({});
  const [updatedSettings, setUpdatedSettings] = useState({
    showPrices: true,
    showReviews: false,
  });

  useEffect(() => {
    if (settings != null) {
      setUpdatedSettings(settings);
    }
  }, []);

  const getInitialState = () => {
    return { hover: false };
  };

  const toggleHover = () => {
    setState(!hoverState);
  };
  const cropTitle = (text) => {
    let lines = 1;
    let cursor = 0;
    let charLineLimit = 13;
    let words = text.split(" ");
    let result = "";
    for (let word of words) {
      if (cursor + word.length <= charLineLimit) {
        result += word + " ";
        cursor += word.length + 1;
      } else {
        lines += 1;
        if (lines > 2) return result;
        result += word + " ";
        cursor = word.length + 1;
      }
    }
    return result;
  };
  function render() {
    if (hoverState) {
      return {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "flex-start",
        cursor: "pointer",
        padding: "2px",
        minWidth: "120px",
        maxWidth: "120px",
        marginBottom: "10px",
      };
    } else {
      return {
        marginBottom: "2px",
        backgroundColor: "#F9F9F9",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "flex-start",
        cursor: "pointer",
        padding: "4px",
        minWidth: "120px",
        maxWidth: "120px",
      };
    }
  }
  let splashupBlockItem = render();

  return (
    <Column
      style={splashupBlockItem}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onTouchStart={toggleHover}
      onTouchEnd={toggleHover}
      onClick={() =>
        handleButtonClick(
          "product-click",
          productData.id,
          productData.storeId,
          productData.URL,
          productData.title
        )
      }
      minHeight="218px"
    >
      <ProductImage alt="" src={productData.image} />
      <ProductTitle>{cropTitle(productData.title)}</ProductTitle>
      {/* The Following displays the reviews */}
      {/* <Row minHeight="35px" maxHeight="35px">
        {updatedSettings.showReviews && productData.reviewsNumber && (
          <Row margin="6px 0 0 0" alignItems="center" flexWrap="wrap">
            <ReactStars
              size={12}
              value={productData.rating}
              edit={false}
              color1={"#E3CDC9"}
              color2={"gray"}
            />
            <span
              style={{ fontSize: "11px", marginLeft: "4px", paddingTop: "0px" }}
            >
              {productData.reviewsNumber} Reviews
            </span>
          </Row>
        )}
      </Row> */}
      <Row minHeight="21px">
        {updatedSettings.showPrices && productData.promoPrice > 0 && (
          <Row>
            <ProductPrice crossed>${productData.price.toFixed(2)}</ProductPrice>
            <ProductPrice>${productData.promoPrice.toFixed(2)}</ProductPrice>
          </Row>
        )}
        {updatedSettings.showPrices &&
          (!productData.promoPrice || productData.promoPrice === 0) && (
            <ProductPriceNormal>
              ${productData.price.toFixed(2)}
            </ProductPriceNormal>
          )}
      </Row>
    </Column>
  );
};

interface Props {
  productData: {
    title: string;
    image: string;
    price: number;
    promoPrice: number;
    id: string;
    storeId: string;
    URL: string;
    reviewsNumber: number;
    rating: number;
  };
  settings: any;
  handleButtonClick: (
    actionType: string,
    productId: string,
    storeId: string,
    productURL: string,
    productTitle: string
  ) => void;
  titleHeight: string;
}

export default ProductMobile;
