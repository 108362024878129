//import mixpanel from 'mixpanel-browser'
import amplitude from 'amplitude-js';

//mixpanel.init('35efdf865c67fe0b16dac2b0ce4be460', {debug: true}); 
var instance1 = amplitude.getInstance().init("fae914421915e696fcea64bac652432c");


export const logEvent = async (event: string, payload: any): Promise<void> => {
  let screenSize = ''
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  window.matchMedia("(max-width: 450px)").matches == true ? screenSize = 'mobile' : screenSize = 'desktop'
  try {
    //mixpanel.track(event, payload);
    let shopId = params.shopid || 'localhost:8080';
    payload = Object.assign(payload, {hostStore: shopId})
    payload = Object.assign(payload, {screenSize: screenSize})    
    //console.log(payload)
    amplitude.getInstance().logEvent(event, payload);
    console.log('event was logged')
  } catch (err) {
    const errorMessage: any = err
    console.error('Could not log the event', errorMessage)
  }

  return
}
