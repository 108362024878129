import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const Row = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex', 
}));

export const Column = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex', 
}));
