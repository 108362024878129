import { useContext, useState } from "react";
import { GlobalProviderState } from "../../contexts/GlobalProvider";
import StoreBlock from "./StoreBlock";
import StoreBlockMobile from "./StoreBlockMobile";

import nextIcon from "../../assets/icons/next.svg";
import previousIcon from "../../assets/icons/previous.svg";

import { Swiper, SwiperSlide } from "swiper/react";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectCards, Navigation, Pagination } from "swiper/modules";
import "./style.css";

const SwiperContainer = styled("div")({
  fontFamily: "'Inter', sans-serif",
  borderRadius: "10px",
  border: "1px solid #D9D9D9",
  padding: "14px",
  maxWidth: "544px",
  backgroundColor: "white",
  position: "relative",
});

const SwiperButton = styled("button")<{
  mobile?: boolean;
  disabled?: boolean;
  layoutOption?: number;
  isNext?: boolean;
  openOfferModal?: boolean;
}>(({ mobile, disabled, layoutOption, isNext, openOfferModal }) => {
  let topValue;
  if (layoutOption === 0) {
    topValue = mobile ? "425px" : "478px";
  } else if (layoutOption === 1) {
    topValue = mobile ? "630px" : "665px";
  } else if (layoutOption === 2) {
    topValue = mobile ? "547px" : "591px";
  }

  return {
    top: topValue,
    width: "89px",
    maxHeight: "30px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
    background: disabled ? "#aeafb1" : "#181a1f",
    borderRadius: "5px",
    cursor: "pointer",
    zIndex: 10,
    border: "none",
    left: isNext ? undefined : "0px",
    right: isNext ? "0px" : undefined,
    visibility: openOfferModal ? "hidden" : "visible",
  };
});

const TopLine = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "20px",
  fontSize: "20px",
  color: "#333333",
});

const LogoLink = styled("div")({
  textDecoration: "none",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  cursor: "pointer",
});

const LogoMobile = styled("div")({
  color: "#414141",
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const FooterRecommendation = styled("div")({
  fontSize: "14px",
  display: "flex",
  justifyContent: "center",
  marginBottom: "4px",
});

// Component
const LayoutOptionPagination = ({
  // adsList,
  handleButtonClick,
  handleEmailSubmit,
  screenSize,
}: Props) => {
  const { widgetSettings, hostName, adsList } = useContext(GlobalProviderState);
  const [openOfferModal, setOpenOfferModal] = useState<boolean>(false);
  const pagination =
    openOfferModal || adsList.length === 1
      ? false
      : {
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 4,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        };

  const layoutOption = widgetSettings.leadGeneration ?? 0;
  const adsCount = adsList.length;
  const getSlideHeight = (layoutOption: number, isMobile: boolean) => {
    if (isMobile) {
      switch (layoutOption) {
        case 0:
          return { minHeight: "432px", maxHeight: "432px" };
        case 1:
          return adsCount === 1
          ? { minHeight: "540px", maxHeight: "540px" }
          : { minHeight: "638px", maxHeight: "638px" };
        case 2:
          return adsCount === 1
            ? { minHeight: "460px", maxHeight: "460px" }
            : { minHeight: "555px", maxHeight: "555px" };
        default:
          return { minHeight: "432px", maxHeight: "432px" };
      }
    } else {
      switch (layoutOption) {
        case 0:
          return { minHeight: "490px", maxHeight: "490px" };
        case 1:
          return adsCount === 1
            ? { minHeight: "580px", maxHeight: "580px" }
            : { minHeight: "674px", maxHeight: "674px" };
        case 2:
          return adsCount === 1
            ? { minHeight: "520px", maxHeight: "520px" }
            : { minHeight: "600px", maxHeight: "600px" };
        default:
          return { minHeight: "49px", maxHeight: "490px" };
      }
    }
  };

  return (
    <section id="splashup-discovery-module">
      {adsList.length > 0 && screenSize === "desktop" && (
        <SwiperContainer id="splashup-container">
          <TopLine>{widgetSettings.topLine}</TopLine>
          <Swiper
            allowTouchMove={false}
            pagination={pagination}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            grabCursor={true}
            centeredSlides={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            modules={[Pagination, EffectCards, Navigation]}
            spaceBetween={50}
            slidesPerView={1}
          >
            {adsList.length > 0 &&
              adsList.map((adInfo, index) => (
                <SwiperSlide
                  key={index}
                  id="styleslide"
                  style={getSlideHeight(layoutOption, false)}
                >
                  <StoreBlock
                    adInfo={adInfo}
                    handleButtonClick={handleButtonClick}
                    handleEmailSubmit={handleEmailSubmit}
                    setOpenOfferModal={setOpenOfferModal}
                    openOfferModal={openOfferModal}
                    layoutOption={layoutOption}
                  />
                </SwiperSlide>
              ))}
            {adsList.length > 1 && (
              <>
                <SwiperButton
                  className="swiper-button-prev"
                  layoutOption={layoutOption}
                  openOfferModal={openOfferModal}
                >
                  <img src={previousIcon} alt="previous" />
                  {`Previous`}
                </SwiperButton>
                <SwiperButton
                  className="swiper-button-next"
                  layoutOption={layoutOption}
                  openOfferModal={openOfferModal}
                  isNext
                >
                  {`Next`}
                  <img src={nextIcon} alt="next" />
                </SwiperButton>
              </>
            )}
          </Swiper>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FooterRecommendation>
                  Recommended by {hostName}
                </FooterRecommendation>
                <LogoLink
                  onClick={() => {
                    window.open("https://www.splashup.co/", "_blank")!.focus();
                  }}
                >
                  <LogoMobile>
                    Powered by
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginLeft: "4px",
                      }}
                    >
                      Splashup
                      <svg
                        style={{ marginLeft: "1px", paddingBottom: "7px" }}
                        width="7"
                        height="7"
                        viewBox="0 0 61 61"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="30.5"
                          cy="30.5"
                          r="29.5"
                          stroke="#414141"
                          strokeWidth="2"
                        />
                        <path
                          d="M21.2756 43V16.8182H31.6051C33.5824 16.8182 35.2699 17.1719 36.6676 17.8793C38.0739 18.5781 39.1435 19.571 39.8764 20.858C40.6179 22.1364 40.9886 23.6406 40.9886 25.3707C40.9886 27.1094 40.6136 28.6051 39.8636 29.858C39.1136 31.1023 38.027 32.0568 36.6037 32.7216C35.1889 33.3864 33.4759 33.7188 31.4645 33.7188H24.5483V29.2699H30.5696C31.6264 29.2699 32.5043 29.125 33.2031 28.8352C33.902 28.5455 34.4219 28.1108 34.7628 27.5312C35.1122 26.9517 35.2869 26.2315 35.2869 25.3707C35.2869 24.5014 35.1122 23.7685 34.7628 23.1719C34.4219 22.5753 33.8977 22.1236 33.1903 21.8168C32.4915 21.5014 31.6094 21.3438 30.544 21.3438H26.8111V43H21.2756ZM35.4148 31.0852L41.9219 43H35.8111L29.4446 31.0852H35.4148Z"
                          fill="#414141"
                        />
                      </svg>
                    </div>
                  </LogoMobile>
                </LogoLink>
              </div>
            </div>
          </div>
        </SwiperContainer>
      )}
      {adsList.length > 0 && screenSize === "mobile" && (
        <SwiperContainer id="splashup-container">
          <TopLine>{widgetSettings.topLine}</TopLine>

          <Swiper
            pagination={pagination}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            grabCursor={true}
            centeredSlides={true}
            modules={[Pagination, EffectCards, Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            touchStartPreventDefault={false}
            allowTouchMove={false}
          >
            {adsList.length > 0 &&
              adsList.map((adInfo, index) => (
                <SwiperSlide
                  key={index}
                  style={getSlideHeight(layoutOption, true)}
                >
                  <StoreBlockMobile
                    adInfo={adInfo}
                    handleButtonClick={handleButtonClick}
                    handleEmailSubmit={handleEmailSubmit}
                    setOpenOfferModal={setOpenOfferModal}
                    openOfferModal={openOfferModal}
                    layoutOption={layoutOption}
                  />
                </SwiperSlide>
              ))}
            {adsList.length > 1 && (
              <>
                <SwiperButton
                  className="swiper-button-prev"
                  mobile
                  layoutOption={layoutOption}
                  openOfferModal={openOfferModal}
                >
                  <img src={previousIcon} alt="previous" />
                  {`Previous`}
                </SwiperButton>
                <SwiperButton
                  className="swiper-button-next"
                  mobile
                  layoutOption={layoutOption}
                  openOfferModal={openOfferModal}
                  isNext
                >
                  {`Next`}
                  <img src={nextIcon} alt="next" />
                </SwiperButton>
              </>
            )}
          </Swiper>
          <Grid margin="20px 0 0 0" data-test="footer">
            <FooterRecommendation>
              Recommended by {hostName}
            </FooterRecommendation>
            <LogoLink
              onClick={() => {
                window.open("https://www.splashup.co/", "_blank")!.focus();
              }}
            >
              <LogoMobile>
                Powered by
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginLeft: "4px",
                  }}
                >
                  Splashup
                  <svg
                    style={{ marginLeft: "1px", paddingBottom: "7px" }}
                    width="7"
                    height="7"
                    viewBox="0 0 61 61"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="30.5"
                      cy="30.5"
                      r="29.5"
                      stroke="#414141"
                      strokeWidth="2"
                    />
                    <path
                      d="M21.2756 43V16.8182H31.6051C33.5824 16.8182 35.2699 17.1719 36.6676 17.8793C38.0739 18.5781 39.1435 19.571 39.8764 20.858C40.6179 22.1364 40.9886 23.6406 40.9886 25.3707C40.9886 27.1094 40.6136 28.6051 39.8636 29.858C39.1136 31.1023 38.027 32.0568 36.6037 32.7216C35.1889 33.3864 33.4759 33.7188 31.4645 33.7188H24.5483V29.2699H30.5696C31.6264 29.2699 32.5043 29.125 33.2031 28.8352C33.902 28.5455 34.4219 28.1108 34.7628 27.5312C35.1122 26.9517 35.2869 26.2315 35.2869 25.3707C35.2869 24.5014 35.1122 23.7685 34.7628 23.1719C34.4219 22.5753 33.8977 22.1236 33.1903 21.8168C32.4915 21.5014 31.6094 21.3438 30.544 21.3438H26.8111V43H21.2756ZM35.4148 31.0852L41.9219 43H35.8111L29.4446 31.0852H35.4148Z"
                      fill="#414141"
                    />
                  </svg>
                </div>
              </LogoMobile>
            </LogoLink>
          </Grid>
        </SwiperContainer>
      )}
    </section>
  );
};

interface Props {
  adsList: any[];
  handleButtonClick: (
    actionType: string,
    productId: string,
    storeId: string,
    productURL: string,
    productTitle: string
  ) => void;
  // handleShowMoreClick: () => void;
  handleEmailSubmit: (storeId: string, userEmail: string) => void;
  showMoreFlag: boolean;
  screenSize: string;
}

export default LayoutOptionPagination;
