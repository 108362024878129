/* eslint-disable react/no-unused-state */
import React, { useReducer } from 'react'
import useGlobalReducer from '../reducers/useGlobalReducer'

const initialTags = ['Cheaper', 'Excellent condition', 'Exciting styles']

const SetInitialState = ()  => {
  
  try {
    // if(localStorage.getItem('splashup-widget-settings')) widgetSettings = JSON.parse(localStorage.getItem('splashup-widget-settings'))
    if (localStorage.getItem('state')) {
      let state = JSON.parse(localStorage.getItem('state')!);
      let sizesSelected = state.sizesSelected || []
      let notifications = state.notifications || []
      let defaultSize = state.defaultSize || 'S'
      let cartItems = state.cartItems || []
      let hostName = state.hostName || ''
      let widgetSettings = state.widgetSettings || {}
      
      state = {
        ...state, 
        widgetSettings: widgetSettings,
        executionFlag: 0,
        activeSlide: 0,
        notifications: notifications, 
        sizesSelected: sizesSelected,
        defaultSize: defaultSize,
        addedToCart: state.addedToCart || [],
        shortlistSendingStatus: state.shortlistSendingStatus,  
        cartItems: cartItems,  
        adsList: [],
        hostName: hostName 
      }
      
      return state
    }
  } catch {
    return  {
      products: [],
      widgetSettings: {},
      productSelected: null,
      notifications: [],
      email: '',
      error: null,
      isLoading: false,
      activeFilters: {},
      executionFlag: 0,
      activeSlide: 0,
      defaultSize: 'S',
      sizesSelected: [],
      userEmail: '',
      importedShortlist: '',
      addedToCart: [],
      shortlistSendingStatus: false,
      cartItems: [],
      adsList: [],
      hostName: ''      
    }
  }
}


export const GlobalProviderState = React.createContext<IGlobalProvider>(SetInitialState())
export const GlobalProviderDispatch = React.createContext<any>(null)

export const GlobalProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const newInitialState = SetInitialState();
  const [state, dispatch] = useReducer(useGlobalReducer, SetInitialState())
  try {
    localStorage.setItem('state', JSON.stringify(newInitialState)); 
  } catch{}
  return (
    <GlobalProviderState.Provider value={{ ...state }}>
      <GlobalProviderDispatch.Provider value={dispatch}>{children}</GlobalProviderDispatch.Provider>
    </GlobalProviderState.Provider>
  )
}

export interface IProduct {
  id: number,
  title: string,
  handle: string,
  description: string,
  published_at: string,
  created_at: string,
  vendor: string,
  type: string,
  tags: string[],
  price: number,
  price_min: number,
  price_max: number,
  available: boolean,
  price_varies: boolean,
  compare_at_price: number,
  compare_at_price_min: number,
  compare_at_price_max: number,
  compare_at_price_varies: boolean,
  variants: any [],
  images: string[],
  featured_image: string,
  options: any[],
  url: string,
  media: any[]    
}

export interface INotification {
  productId: number, 
  active: string[]
}
export interface IGlobalProvider {
  products: IProduct[] | null
  productSelected: IProduct | null
  notifications: any[],
  email: string,
  error: string | null
  isLoading: boolean
  activeFilters: any,
  executionFlag: number,
  activeSlide: number,
  defaultSize: string,
  sizesSelected: {itemId: number, variantId: number, selectedSize: string}[],
  userEmail: string,
  importedShortlist: string,
  addedToCart: number[],
  shortlistSendingStatus: boolean,
  cartItems: any[],
  widgetSettings: any,
  adsList: any[],
  hostName: string
}
