import { useEffect, useState, useCallback, Dispatch, SetStateAction } from "react";
import { styled } from "@mui/material/styles";
import Product from "./Product";
import USPIcon from "../uspIcons";
import OfferLayout from "./OfferLayout";
import { Column, Row } from "../../design/components/grid";
import StoreOfferMobileEmbedded from "./StoreOfferMobileEmbedded";
import StoreOfferMobile from "./StoreOfferMobile";
import { logEvent } from "../../utils/logEvent";

const StoreBlockContainer = styled("div")({
  marginBottom: "25px",
  position: "relative",
  width: "99%",
  paddingLeft: "2px",
  minHeight: "350px",
});

const BlockHeaderStoreLogo = styled("img")({
  width: "40px",
  height: "40px",
});

const BlockShopInfo = styled("div")({
  marginLeft: "8px",
  marginRight: "8px",
});

const BlockShopName = styled("div")({
  fontSize: "16px",
  fontWeight: "bold",
  color: "black",
  paddingBottom: "4px",
});

const BlockShopText = styled("div")({
  color: "#606060",
  fontSize: "14px",
  marginTop: "5px",
  minHeight: "20px",
  maxHeight: "20px",
  overflow: "hidden",
});

const BlockHeaderRightLink = styled("button")({
  color: "#606060",
  textDecoration: "none",
  fontSize: "14px",
  backgroundColor: "#F4F4F4",
  border: "none",
  width: "96px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: "10px",
  height: "25px",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#F4F4F4",
  },
});

const BlockItemList = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  marginTop: "4px",
  overflowX: "auto",
  paddingBottom: "10px",
  minHeight: "235px",
});

const BlockUSPLine = styled("div")({
  display: "flex",
  flexDirection: "row",
  marginTop: "16px",
  marginBottom: "14px",
  marginLeft: "6px",
  minHeight: "33px",
  maxHeight: "33px",
});

const USPElement = styled("div")({
  display: "flex",
  alignItems: "center",
  color: "#222",
  marginRight: "14px",
});

const USPItemTitle = styled("div")({
  marginLeft: "4px",
  fontSize: "13px",
  fontWeight: "500",
});

const BlockOfferButton = styled("button")({
  backgroundColor: "#fff",
  fontFamily: "Inter, sans-serif",
  fontSize: "13px",
  color: "black",
  border: "none",
  display: "flex",
  borderRadius: "10px",
  alignItems: "center",
  marginRight: "4px",
  width: "74px",
  height: "25px",
  justifyContent: "space-around",
  "&:hover": {
    backgroundColor: "#F4F4F4",
  },
});

const BlockOfferIcon = styled("svg")({
  width: "20px",
  marginLeft: "2px",
});

const OfferSectionHeader = styled("div")({
  minHeight: "46px",
  maxHeight: "46px",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  fontSize: "20px",
});

const StoreBlock = ({
  adInfo,
  handleButtonClick,
  handleEmailSubmit,
  layoutOption,
  setOpenOfferModal,
  openOfferModal,
}: Props) => {
  const [showOffer, setShowOffer] = useState(false);
  const [productTitleHeight, setProductTitleHeight] = useState("28px");

  const handleOfferModalOpen = () => {
    logEvent("add_clicked", {
      targetStore: adInfo.storeData.name,
      widgetOption: "promo",
      actionType: "offer-modal-opened",
    });
    setOpenOfferModal(true);
  };

  const calculateHeight = useCallback(() => {
    let flag = 0;
    for (const product of adInfo.productsData) {
      if (getLineCount(product.title) > 1) {
        flag = 1;
        break;
      }
    }
    setProductTitleHeight(flag === 0 ? "16px" : "28px");
  }, [adInfo.productsData]);

  useEffect(() => {
    calculateHeight();
  }, [calculateHeight]);

  const getLineCount = (text: string) => {
    let lines = 1;
    let cursor = 0;
    const charLineLimit = 25;
    const words = text.split(" ");
    for (const word of words) {
      if (cursor + word.length <= charLineLimit) cursor += word.length + 1;
      if (cursor + word.length > charLineLimit) {
        lines++;
        cursor = word.length + 1;
      }
    }
    return lines;
  };

  const offerData = {
    storeId: adInfo.storeData.storeId,
    store: adInfo.storeData.name,
    storeLogo: adInfo.storeData.storeLogo,
    storeURL: adInfo.storeData.storeURL,
    text: adInfo.storeData.offerData.text,
    couponCode: adInfo.storeData.offerData.couponCode,
  };

  return (
    <StoreBlockContainer id={adInfo.storeData.storeId}>
      {showOffer && (
        <OfferLayout
          offerData={offerData}
          setShowOffer={setShowOffer}
          handleButtonClick={handleButtonClick}
        />
      )}
      {openOfferModal && (
        <StoreOfferMobile
          offerData={adInfo.storeData.offerData}
          modalStatus={openOfferModal}
          storeName={adInfo.storeData.name}
          storeId={adInfo.storeData.storeId}
          setShowOffer={setOpenOfferModal}
          handleEmailSubmit={handleEmailSubmit}
        />
      )}
      <Column
        justifyContent="space-between"
        minHeight="60px"
        maxHeight="60px"
        data-test="column-test"
      >
        <Row justifyContent="space-between">
          <Row alignItems="center" gap="20px">
            <BlockHeaderStoreLogo src={adInfo.storeData.storeLogo} />
            <BlockShopInfo>
              <BlockShopName>{adInfo.storeData.name}</BlockShopName>
            </BlockShopInfo>
          </Row>
          <Row alignItems="flex-start">
            {adInfo.storeData.offerData.text &&
              layoutOption !== 1 &&
              layoutOption !== 2 && (
                <BlockOfferButton onClick={() => setShowOffer(true)}>
                  Offer
                  <BlockOfferIcon
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M17 14h-4.341a6 6 0 1 1 0-4H23v4h-2v4h-4v-4zM7 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
                    </g>
                  </BlockOfferIcon>
                </BlockOfferButton>
              )}
            <BlockHeaderRightLink
              onClick={() =>
                handleButtonClick(
                  "shop-click",
                  "",
                  adInfo.storeData.storeId,
                  "https://" + adInfo.storeData.storeURL,
                  ""
                )
              }
            >
              Visit shop
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.79289 6.5L4.14645 2.85355C3.95118 2.65829 3.95118 2.34171 4.14645 2.14645C4.34171 1.95118 4.65829 1.95118 4.85355 2.14645L8.85355 6.14645C9.04882 6.34171 9.04882 6.65829 8.85355 6.85355L4.85355 10.8536C4.65829 11.0488 4.34171 11.0488 4.14645 10.8536C3.95118 10.6583 3.95118 10.3417 4.14645 10.1464L7.79289 6.5Z"
                  fill="#545454"
                />
              </svg>
            </BlockHeaderRightLink>
          </Row>
        </Row>
        <BlockShopText>{adInfo.storeData.actionPhrase}</BlockShopText>
      </Column>

      {adInfo.storeData.USPData[0] ? (
        <BlockUSPLine>
          {adInfo.storeData.USPData.map((USP) => (
            <USPElement key={USP.text}>
              <USPIcon type={USP.icon} size="15" color="#8A8C94" />
              <USPItemTitle>{USP.text}</USPItemTitle>
            </USPElement>
          ))}
        </BlockUSPLine>
      ) : (
        <BlockUSPLine />
      )}

      <BlockItemList>
        {adInfo.productsData.map(
          (productData: {
            title: string;
            image: string;
            price: number;
            reviewsNumber: number;
            rating: number;
            promoPrice: number;
            id: string;
            storeId: string;
            URL: string;
          }) => (
            <Product
              key={productData.id}
              productData={productData}
              settings={adInfo.storeData.settings}
              handleButtonClick={handleButtonClick}
              titleHeight={productTitleHeight}
            />
          )
        )}
      </BlockItemList>

      {layoutOption === 1 && (
        <div
          className="splashup-block-offer-section-mobile"
          style={{ marginTop: "10px" }}
        >
          <div
            className="splashup-block-offer-section-mobile-header"
            style={{ maxHeight: "46px", minHeight: "46px", overflow: "hidden" }}
          >
            <svg
              className="splashup-offer-icon"
              width="44"
              height="43"
              viewBox="0 0 44 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M35.8418 19.4434L17.7386 19.7594L17.7962 23.0601L35.8994 22.7441L35.8418 19.4434Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M35.8701 21.0938L32.5788 21.1512L32.6592 25.7575L35.9505 25.7L35.8701 21.0938Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.9336 21.1797L27.6422 21.2371L27.7226 25.8434L31.014 25.7859L30.9336 21.1797Z"
                fill="black"
              />
              <mask
                id="mask0_4673_76847"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="7"
                y="15"
                width="13"
                height="13"
              >
                <path
                  d="M19.3232 15.4258L7.77108 15.6274L7.97273 27.1796L19.5249 26.9779L19.3232 15.4258Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_4673_76847)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.6071 18.8134C14.9703 18.7896 16.0952 19.8787 16.119 21.2456C16.1429 22.6129 15.0567 23.7406 13.6935 23.7644C12.33 23.7882 11.2054 22.6991 11.1815 21.3318C11.1577 19.9649 12.2436 18.8372 13.6071 18.8134ZM19.4104 21.1882C19.3547 17.9981 16.7308 15.4571 13.5495 15.5127C10.3681 15.5682 7.83451 18.1991 7.8902 21.3893C7.94588 24.5794 10.5698 27.1203 13.7511 27.0648C16.9325 27.0093 19.4661 24.3783 19.4104 21.1882Z"
                  fill="black"
                />
              </g>
            </svg>
            <OfferSectionHeader>
              You've unlocked: {adInfo.storeData.offerData.text}
            </OfferSectionHeader>
          </div>
          <div className="splashup-block-embedded-offer-section-mobile-action-section">
            <StoreOfferMobileEmbedded
              offerData={adInfo.storeData.offerData}
              modalStatus={showOffer}
              storeName={adInfo.storeData.name}
              storeId={adInfo.storeData.storeId}
              storeURL={adInfo.storeData.storeURL}
              setShowOffer={setShowOffer}
              handleEmailSubmit={handleEmailSubmit}
            />
          </div>
        </div>
      )}

      {layoutOption === 2 && (
        <div
          className="splashup-block-offer-section-mobile"
          style={{ marginTop: "10px" }}
        >
          <div
            className="splashup-block-offer-section-mobile-header"
            style={{ maxHeight: "46px", minHeight: "46px", overflow: "hidden" }}
          >
            <div className="splashup-block-offer-section-mobile-header">
              You've unlocked: {adInfo.storeData.offerData.text}
            </div>
          </div>
          <div
            style={{ display: "flex", marginTop: "20px", marginBottom: "20px" }}
          >
            <svg
              className="splashup-offer-icon"
              width="44"
              height="43"
              viewBox="0 0 44 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M35.8418 19.4434L17.7386 19.7594L17.7962 23.0601L35.8994 22.7441L35.8418 19.4434Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M35.8701 21.0938L32.5788 21.1512L32.6592 25.7575L35.9505 25.7L35.8701 21.0938Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.9336 21.1797L27.6422 21.2371L27.7226 25.8434L31.014 25.7859L30.9336 21.1797Z"
                fill="black"
              />
              <mask
                id="mask0_4673_76847"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="7"
                y="15"
                width="13"
                height="13"
              >
                <path
                  d="M19.3232 15.4258L7.77108 15.6274L7.97273 27.1796L19.5249 26.9779L19.3232 15.4258Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_4673_76847)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.6071 18.8134C14.9703 18.7896 16.0952 19.8787 16.119 21.2456C16.1429 22.6129 15.0567 23.7406 13.6935 23.7644C12.33 23.7882 11.2054 22.6991 11.1815 21.3318C11.1577 19.9649 12.2436 18.8372 13.6071 18.8134ZM19.4104 21.1882C19.3547 17.9981 16.7308 15.4571 13.5495 15.5127C10.3681 15.5682 7.83451 18.1991 7.8902 21.3893C7.94588 24.5794 10.5698 27.1203 13.7511 27.0648C16.9325 27.0093 19.4661 24.3783 19.4104 21.1882Z"
                  fill="black"
                />
              </g>
            </svg>
            <button
              onClick={() => {
                handleOfferModalOpen();
              }}
              className="splashup-offer-shop-button"
            >
              Get Your Code Now
            </button>
          </div>
        </div>
      )}
    </StoreBlockContainer>
  );
};

interface Props {
  adInfo: {
    storeData: {
      name: string;
      actionPhrase: string;
      storeLogo: string;
      storeId: string;
      storeURL: string;
      offerData: {
        text: string;
        couponCode: string;
      };
      USPData: {
        icon: string;
        text: string;
      }[];
      settings: any;
    };
    productsData: {
      title: string;
      image: string;
      price: number;
      promoPrice: number;
      id: string;
      storeId: string;
      URL: string;
    }[];
  };
  handleButtonClick: (
    actionType: string,
    productId: string,
    storeId: string,
    productURL: string,
    productTitle: string
  ) => void;
  handleEmailSubmit: (storeId: string, userEmail: string) => void;
  layoutOption: number;
  setOpenOfferModal: Dispatch<SetStateAction<boolean>>;
  openOfferModal: boolean;
}

export default StoreBlock;
