import { IGlobalProvider } from '../contexts/GlobalProvider'

const useTodoReducer = (state: IGlobalProvider, action: IAction) => {
  
//   const asyncLocalStorage = {
//     setItem: function (key:string, value:string) {
//         return Promise.resolve().then(function () {
//             localStorage.setItem(key, value);
//         });
//     },
//     getItem: function (key:string ) {
//         return Promise.resolve().then(function () {
//             return localStorage.getItem(key);
//         });
//     }
// };

  switch (action.type) {
    case 'START_FETCHING_PRODUCTS': {
      return { ...state, products: null, productSelected: null, error: null, isLoading: true }
    }
    case 'SET_ADS_LIST': {
      return { ...state, adsList: action.payload }
    }
    case 'SET_HOST_NAME': {
      return { ...state, hostName: action.payload }
    }
    
    case 'SET_WIDGET_SETTINGS': {
      let widgetSettings = action.payload
      if (widgetSettings == undefined || widgetSettings==null) {
        widgetSettings = {
          topLine: 'We think you’ll love:',
          widgetOption: 'regular',
          showPrices: true,
          showReviews: false
        }
      }
      else {
        if (widgetSettings.topLine == undefined) {
          widgetSettings.topLine = 'We think you’ll love:'
        }
        if (widgetSettings.widgetOption == undefined) {
          widgetSettings.widgetOption = 'regular'
        }
        if (widgetSettings.showPrices == undefined) {
          widgetSettings.showPrices = true
        }
        if (widgetSettings.showReviews == undefined) {
          widgetSettings.showReviews = false
        }
        
      }
      return { ...state, widgetSettings: widgetSettings }
    }
    case 'SHORTLIST_FETCH_SUCCESS': {
      return { ...state, products: action.payload.products, productSelected: action.payload.productSelected, notifications: action.payload.notifications, sizesSelected: action.payload.sizesSelected, importedShortlist: action.payload.importedShortlist}
    }

    case 'PRODUCTS_FETCH_ERROR': {
      return { ...state, error: action.payload, isLoading: false }
    }
    
    case 'SET_SELECTED_PRODUCT': {
      //console.log('changedFocus true')
      return { ...state, productSelected: action.payload}
    }

    case 'SLIDE_CHANGED': {
      //console.log('changedFocus false')
      return { ...state, activeSlide: action.payload }
    }
    
    case 'SELECT_PRODUCT': {
      const productSelected = state.products!.find(product => product.id === action.payload)
      return { ...state, productSelected: productSelected, activeSlide: 0 }
    }

    case 'REMOVE_ITEM': {
      const newProducts=state.products!.filter((item)=>item.id !== action.payload)
      return { ...state, products: newProducts, productSelected: newProducts[0]}
    }
    case 'NOTIFICATION_IS_SET': {
      return { ...state, notifications: action.payload}
    }
    
    case 'SIZE_SELECTED': {
      let currentSizesSelected = state.sizesSelected
      let newSizeSelected = currentSizesSelected.filter(item => item.itemId !== action.payload.itemId)
      newSizeSelected.push(action.payload)
      return { ...state, defaultSize: action.payload.selectedSize, sizesSelected: newSizeSelected}
    }

    case 'SET_USER_EMAIL': {
      return { ...state, userEmail: action.payload}
    }
    
    case 'SET_CART_ITEMS': {
      return { ...state, cartItems: action.payload}
    }

    case 'SET_SHORTLISTING_STATUS': {
      return { ...state, shortlistSendingStatus: action.payload}
    }
    
    case 'SET_ADD_TO_CART': {
      let newAddedToCart = state.cartItems
      newAddedToCart.push(action.payload)
      return { ...state, cartItems: newAddedToCart}
    }

    case 'SELECT_FILTER_OPTION': {
      const currentActiveFilters = state.activeFilters
      const optionSelected = action.payload
      let flag = state.executionFlag
      flag++
      console.log(flag)
      
      let newActiveFilters = currentActiveFilters;
      if (1) {
        
        if (newActiveFilters[optionSelected.filter].activeOptions.includes(optionSelected.option))
        {
          newActiveFilters[optionSelected.filter].activeOptions = newActiveFilters[optionSelected.filter].activeOptions.filter((option : any) => option !== optionSelected.option)
          console.log('deactivate ' + optionSelected.option)
          optionSelected.children.map( (child : any) =>
            {
              newActiveFilters[child].parentsCount--
              if (newActiveFilters[child].parentsCount === 0)
              {
                delete newActiveFilters[child]
              }

            }
          )
        }
        else {
          newActiveFilters[optionSelected.filter].activeOptions = [...newActiveFilters[optionSelected.filter].activeOptions, optionSelected.option]
          console.log('activate ' + newActiveFilters[optionSelected.filter].activeOptions )
          optionSelected.children.map( (child : any) =>
            {
              if (Object.keys(newActiveFilters).includes(child))
              {
                newActiveFilters[child].parentsCount++
              }
              else {
                newActiveFilters[child] = {}
                newActiveFilters[child].activeOptions = []
                newActiveFilters[child].parentsCount = 1
              }  
              
            }
          )
        }      
        console.log(newActiveFilters)
      } 
      //localStorage.setItem('state', JSON.stringify({ ...state, activeFilters: newActiveFilters, executionFlag: flag })); 
      return { ...state, activeFilters: newActiveFilters, executionFlag: flag }
    }

    

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export interface IAction {
  type:
    | 'START_FETCHING_PRODUCTS'
    | 'SHORTLIST_FETCH_SUCCESS'
    | 'PRODUCTS_FETCH_ERROR'
    | 'SELECT_PRODUCT'
    | 'SELECT_FILTER_OPTION'
    | 'REMOVE_ITEM'
    | 'SET_SELECTED_PRODUCT'
    | 'SLIDE_CHANGED'
    | 'NOTIFICATION_IS_SET'
    | 'SIZE_SELECTED'
    | 'SET_USER_EMAIL'
    | 'SET_ADD_TO_CART'
    | 'SET_SHORTLISTING_STATUS'
    | 'SET_CART_ITEMS'
    | 'SET_ADS_LIST'
    | 'SET_HOST_NAME'
    | 'SET_WIDGET_SETTINGS'
  payload?: any
}

export default useTodoReducer
